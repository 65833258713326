/* Reset and global styles */
body,
html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #f45b8c;
  color: #f45b8c;
}

.container {
  background-color: #fdf3f3;
  border-radius: 8px;
  padding: 20px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #db0e50;
}

/* Links */
a {
  color: #f45b8c;
  text-decoration: none;
}

a:hover {
  color: #f3e5ab;
  text-decoration: underline;
}

/* Buttons */
.btn {
  background-color: #f45b8c;
  color: #fdf3f3;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #ffb9b9;
}

/* Inputs and form elements */
input,
textarea {
  border: 1px solid #f3e5ab;
}
/* In your CSS file */
.signin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.signin-title {
  margin-bottom: 20px;
}

.signin-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px; /* Or whatever width you prefer for the form */
}

.signin-input {
  margin-bottom: 10px;
  width: 100%;
  padding: 10px;
}

.signin-button {
  padding: 10px 20px;
  margin-top: 10px;
}


input:focus,
textarea:focus {
  border-color: #fad6a5;
  box-shadow: 0 0 8px rgba(250, 214, 165, 0.5);
}
.editor {
  border: 1px solid #ccc;
  min-height: 200px;
  padding: 10px;
}

/* Misc */
.small-text {
  color: #f45b8c;
}
.card {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.card-content {
  margin-top: 10px;
}

.navbar {
  background-color: #f6c7c7;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-nav .nav-link {
  color: #f12b6b;
}
.navbar-nav {
  width: 100%;
  justify-content: right;
}

.navbar-nav .nav-link {
  margin-right: 1rem;
  margin-left: 1rem;
  
}
.navbar-light .navbar-toggler-icon {
  background-color: #ffb9b9;
}

/* Optional: Change the active link color */
.navbar-light .navbar-nav .active>.nav-link {
  color: #ffb9b9;
}

@media (min-width: 768px) {
  /* Add styles specific to tablets or small screens */
}

/* For desktops */
@media (min-width: 1024px) {
  /* Add styles specific to desktops or large screens */
}